import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import CarLogoImg from '../../../assets/images/car-logo.png';
import CarLogoDarkImg from '../../../assets/images/car-logo-dark.png';

interface ILogoProps {
  color?: 'white' | 'dark ';
  bgColor?: 'white' | 'dark';
}

const LogoContainer = styled.a`
  ${tw`
        flex
        items-center
        ml-2        
    `}
`;

const LogoText = styled.div`
  ${tw`
        text-xl
        md: text-2xl
        font-bold
        text-black
        m-1
        `}
  ${({ color }: any) => (color === 'white' ? tw`text-white` : tw`text-black`)}
` as any;

const Image = styled.div`
  width: auto;
  ${tw`h-6 md:h-9`}

  img {
    width: auto;
    height: 100%;
  }
`;

export function Logo(props: ILogoProps) {
  const { color, bgColor } = props;

  return (
    <LogoContainer href='/'>
      <Image>
        <img
          alt="car logo"
          src={bgColor === 'dark' ? CarLogoDarkImg : CarLogoImg}
        />
      </Image>
      <LogoText color={color || 'dark'}>rentAcar.</LogoText>
    </LogoContainer>
  );
}
